<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row dense>
      <v-col cols="12">
        {{ $t('usernameExists') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
	name: "TeamInviteUserExists",
	props: {
		userName: {
      type: String,
      default: ''
    },
		email: {
      type: String,
      default:() => {}
    }
	}
}
</script>

<style scoped>

</style>